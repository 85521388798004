import "core-js/stable";
import "./styles/base.scss";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ModalProvider } from "react-modal-hook";
import { globalPrintStyles } from "./components/__styles__/globalStyles";
import { StagingBanner } from "./components/AdminApp/AccountsApp/StagingBanner";
import { AppScaffold } from "./components/AppScaffold";
import { MainRoutes } from "./components/Guest/MainRoutes";
import { setupEntrypoint } from "./utils/entrypoint";
import { createI18nInstance } from "./utils/i18n/i18n";
const { history } = setupEntrypoint();
void createI18nInstance();

globalPrintStyles();

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <AppScaffold history={history} app="guest">
      <ModalProvider>
        <StagingBanner />
        <MainRoutes />
      </ModalProvider>
    </AppScaffold>
  </Suspense>,
  document.getElementById("app")
);
