import React, { useContext } from "react";
import * as Sentry from "@sentry/browser";
import { Redirect } from "react-router-dom";
import {
  INTAKE_SOURCE,
  SUBMISSION_CATEGORY,
  SUBMISSION_TYPE_MODULE,
} from "common/constants";
import { arrayHasAtLeastOneItem } from "common/utils/arrays";
import { AuthContext } from "../../Authorization/AuthContext";
import { CreateSubmission } from "../../Submissions/CreateSubmission";
import { HELPSCOUT_BEACON_TYPES, useHelpscoutBeacon } from "../utils";

const UploadElevationCertificatesPage = () => {
  const { account } = useContext(AuthContext);

  useHelpscoutBeacon(HELPSCOUT_BEACON_TYPES.EC);

  const submissionTypes = account?.submissionTypes.filter(
    type =>
      type.category === SUBMISSION_CATEGORY.PERMITTING &&
      type.intakeSource === INTAKE_SOURCE.EXTERNAL &&
      type.modules.includes(SUBMISSION_TYPE_MODULE.EC_SUBMISSION)
  );

  if (!arrayHasAtLeastOneItem(submissionTypes)) {
    return <Redirect to={"/"} />;
  }

  if (submissionTypes.length > 1) {
    Sentry.captureMessage(
      `Multiple submission types match criteria for guest EC upload: ${submissionTypes
        .map(st => st.id)
        .join(", ")}`
    );
  }

  return <CreateSubmission submissionTypeId={submissionTypes[0].id} />;
};

export default UploadElevationCertificatesPage;
