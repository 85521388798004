import React, { useContext, useMemo } from "react";
import { NetworkStatus } from "@apollo/client";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { useLocation } from "react-router";
import {
  BUILDING_ELEVATION_SOURCE,
  DOCUMENT_TYPE_NAME,
} from "common/constants";
import { formatDate } from "common/utils/strings";
import { parcelFieldNameOverridesByKey } from "common-client/utils/parcels";
import {
  GetDocumentUploadsQuery,
  GetDocumentUploadsQueryVariables,
  SortDir,
  useGetDocumentUploadsLazyQuery,
} from "../../../generated/graphql";
import {
  buildLocalTableInfo,
  useLocalTableDisplayConfig,
} from "../../../hooks/useTableDisplayConfig";
import { AuthContext } from "../../Authorization/AuthContext";
import { ACTION_COLUMN_DEF_CONSTANTS } from "../../Common/ActionCell";
import { FILTER_TYPE } from "../../Common/Filters";
import { sanityCheckLocalColumnOrder } from "../../Common/FullWidthTable/utils";
import {
  useLegacyTableStateInURL,
  useManualPaginationConfig,
} from "../../Common/Tables/hooks";
import Table from "../../Common/Tables/Table";

type PublicFilesTableProps = {
  actionCell: (data: {
    original: GetDocumentUploadsQuery["documentUploads"]["data"][number];
    onUpdate: () => {};
    prevLocation?: string;
  }) => JSX.Element;
};

const buildElevationSourceOptions = [
  { label: "All", value: null },
  {
    label: "Construction drawings",
    value: BUILDING_ELEVATION_SOURCE.CONSTRUCTION_DRAWINGS,
  },
  {
    label: "Under construction",
    value: BUILDING_ELEVATION_SOURCE.BUILDING_UNDER_CONSTRUCTION,
  },
  {
    label: "Finished construction",
    value: BUILDING_ELEVATION_SOURCE.FINISHED_CONSTRUCTION,
  },
];

export const PublicFilesTable = ({ actionCell }: PublicFilesTableProps) => {
  const { account, user, admin } = useContext(AuthContext);
  const location = useLocation();
  const [
    queryDocumentUploads,
    { previousData, data: currentData, networkStatus, error, loading, refetch },
  ] = useGetDocumentUploadsLazyQuery();

  const accountDocumentTypes = account?.accountDocumentTypes;

  const elevationCertificateDocumentTypeId = accountDocumentTypes?.find(
    accountDocumentType =>
      accountDocumentType.name === DOCUMENT_TYPE_NAME.ELEVATION_CERTIFICATE
  )?.id;

  const filterConfig = [
    { type: FILTER_TYPE.TEXT, label: "Address", key: "addressPart" },
    { type: FILTER_TYPE.TEXT, label: "City", key: "city" },
    { type: FILTER_TYPE.TEXT, label: "Parcel ID", key: "parcel" },
    {
      type: FILTER_TYPE.SELECT,
      label: "EC type",
      key: "buildingElevationSource",
      options: buildElevationSourceOptions,
    },

    { type: FILTER_TYPE.DATE, label: "Date issued", key: "issuedAt" },
    { type: FILTER_TYPE.DATE, label: "Date uploaded", key: "createdAt" },
  ];

  const data =
    networkStatus === NetworkStatus.setVariables ? previousData : currentData;

  const [initialTableState, setTableStateInURL] = useLegacyTableStateInURL({
    defaultSortingParams: [{ id: "property.streetAddress", desc: false }],
    filterConfig,
  });

  const manualPaginationConfig = useManualPaginationConfig({
    ...initialTableState.pagination,
    currentTotalPages: currentData?.documentUploads.pageInfo.totalPages,
    previousTotalPages: previousData?.documentUploads.pageInfo.totalPages,
  });

  const { parcelId } = parcelFieldNameOverridesByKey({
    parcelFieldNameOverrides: account?.parcelFieldNameOverrides,
  });

  const columns = useMemo<
    Array<ColumnDef<GetDocumentUploadsQuery["documentUploads"]["data"][number]>>
  >(
    () => [
      {
        id: "property.streetAddress",
        header: "Address",
        accessorFn: row => row.property?.streetAddress,
      },
      {
        id: "property.city",
        header: "City",
        accessorFn: row => row.property?.city,
      },
      {
        id: "property.parcel.parcelId",
        header: parcelId?.value,
        accessorFn: row => row.property?.parcel?.parcelId,
      },
      {
        id: "issuedAt",
        header: "Date issued",
        accessorFn: row => row.issuedAt,
        cell: row => formatDate(row.row.original.issuedAt),
      },
      {
        id: "createdAt",
        header: "Date uploaded",
        accessorFn: row => row.createdAt,
        cell: row => formatDate(row.row.original.createdAt),
      },

      {
        ...ACTION_COLUMN_DEF_CONSTANTS,
        size: 40,
        minSize: 40,
        cell: row =>
          actionCell({
            original: row.row.original,
            onUpdate: refetch!,
            prevLocation: location.pathname + location.search,
          }),
      },
    ],
    [data?.documentUploads.data]
  );

  const loadingDetails = {
    loading,
    loadingText: "Loading files",
    noDataText: "No files found",
  };

  const search = ({
    filters,
    sort,
    page,
  }: {
    filters: Record<string, unknown>;
    sort: SortingState;
    page: number;
  }) => {
    const sortParams = sort.map(s => ({
      sortBy: s.id,
      sortDir: s.desc ? SortDir.DESC : SortDir.ASC,
    }));

    const variables: GetDocumentUploadsQueryVariables = {
      ...filters,
      isGuest: true,
      sort: sortParams,
      page,
    };

    if (!elevationCertificateDocumentTypeId) {
      return;
    } else {
      variables.accountDocumentTypeId = elevationCertificateDocumentTypeId;
    }

    void queryDocumentUploads({
      variables,
    });
  };

  if (error) {
    return <div />;
  }

  const pathname = location.pathname;

  const { id: tableId } = buildLocalTableInfo({
    entityId: user?.id ?? admin?.id,
    pathname,
  });

  const { getLocalTableState, setLocalColumnOrder } =
    useLocalTableDisplayConfig({
      tableId,
      defaultValue: {
        columnOrder: columns.map(column => column.id!),
        columnSizing: {},
        sorting: [],
      },
    });

  const localTableConfig = getLocalTableState();

  const { columnOrder: localColumnOrder } = localTableConfig;

  const updatedColumnOrder = sanityCheckLocalColumnOrder({
    localColumnOrder,
    defaultColumnIds: columns.map(column => column.id!),
  });

  if (updatedColumnOrder) {
    setLocalColumnOrder(updatedColumnOrder);
  }

  const initialColumns = updatedColumnOrder
    ? updatedColumnOrder.map(id => columns.find(c => c.id === id)!)
    : localColumnOrder.map((id: string) => columns.find(c => c.id === id)!);

  return (
    <Table
      columns={initialColumns}
      previousData={previousData?.documentUploads.data}
      currentData={data?.documentUploads.data ?? []}
      loadingDetails={loadingDetails}
      tableStyleDetails={{ hasHighlights: true, hasRowActions: true }}
      manualPaginationConfig={{
        ...manualPaginationConfig,
        pageCount: data?.documentUploads.pageInfo.totalPages ?? 1,
      }}
      initialState={initialTableState}
      setTableStateInURL={setTableStateInURL}
      filterable={{ filterConfigurations: filterConfig, search }}
    />
  );
};
