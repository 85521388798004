import React from "react";
import { NetworkStatus } from "@apollo/client";
import { ColumnDef, ColumnSort } from "@tanstack/react-table";
import { lowerCase } from "lodash";
import { useParams } from "react-router";
import { buildLink } from "common/routing";
import {
  isSubmissionTable,
  OrderBy,
  TABLE_NAMES,
  TopLevelTableNames,
} from "common/utils/queryBuilder";
import {
  GetQueryTableDataQueryVariables,
  SavedView as SavedViewType,
  useGetQueryTableDataLazyQuery,
  useGetSavedViewQuery,
  useGetTableConfigQuery,
} from "../../../generated/graphql";
import { setTitle } from "../../../utils/title";
import { MarkdownContainer } from "../../Common/__styles__/MarkdownContainer";
import {
  ACTION_COLUMN_DEF_CONSTANTS,
  ActionCell,
} from "../../Common/ActionCell";
import { FullWidthTable } from "../../Common/FullWidthTable/FullWidthTable";
import { Attribute } from "../../Common/FullWidthTable/types";
import {
  initializeColumns,
  mergeFieldGroups,
  MinimalColumnDef,
} from "../../Common/FullWidthTable/utils";
import {
  useManualPaginationConfig,
  useQueryDescription,
} from "../../Common/Tables/hooks";
import { buildColumnDefinitions } from "../../Common/Tables/utils";
import { QueryFileTableResult } from "../../DocumentUploads/__queries__/table";
import {
  FILES_TABLE_CUSTOM_CELLS,
  FILES_TABLE_REQUIRED_FIELDS,
} from "../../DocumentUploads/FilesTable";
import { ActionsProps } from "../../Inputs/DropdownMenu";
import { QueryPropertyTableResult } from "../../Property/__queries__/table";
import {
  PROPERTIES_TABLE_REQUIRED_FIELDS,
  PROPERTY_TABLE_CUSTOM_CELLS,
} from "../../Property/PropertiesTable";
import { QuerySubmissionTableResult } from "../../Submissions/__queries__/table";
import {
  SUBMISSION_TABLE_CUSTOM_CELLS,
  SUBMISSIONS_TABLE_REQUIRED_FIELDS,
} from "../../Submissions/SubmissionsTable";
import { Loading } from "../__styles__/Guest";
import AccessDeniedPage, { ACCESS_DENIED_RESOURCE } from "../AccessDeniedPage";
import Layout from "../Layout";
import { PageSubheader } from "../PageSubheader";
import { accountTitle } from "../utils";

export const REQUIRED_FIELDS = (tableName: TABLE_NAMES) => {
  if (isSubmissionTable(tableName)) {
    return SUBMISSIONS_TABLE_REQUIRED_FIELDS(tableName);
  } else if (tableName === TABLE_NAMES.PROPERTIES) {
    return PROPERTIES_TABLE_REQUIRED_FIELDS;
  } else if (tableName === TABLE_NAMES.FILES) {
    return FILES_TABLE_REQUIRED_FIELDS;
  } else {
    return [];
  }
};

const getDefaultSort = ({
  savedViewOrderBy,
}: {
  savedViewOrderBy: OrderBy;
}): [ColumnSort] => {
  return [
    {
      id: `${savedViewOrderBy.table}.${savedViewOrderBy.field}`,
      desc: savedViewOrderBy.direction === "DESC",
    },
  ];
};

const SavedView = ({
  savedView,
}: {
  savedView: Omit<SavedViewType, "tableType">;
}) => {
  const { data: columnConfigResponse, loading: loadingColumnConfig } =
    useGetTableConfigQuery({
      variables: { table: savedView.query.table },
      fetchPolicy: "network-only",
    });

  const [
    querySavedView,
    {
      previousData,
      data: currentData,
      networkStatus,
      loading: loadingQuerySavedView,
      error,
    },
  ] = useGetQueryTableDataLazyQuery({
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  setTitle(`${savedView.name} | ${accountTitle()}`);

  const tableName = savedView.query.table as TopLevelTableNames;
  const defaultSort = getDefaultSort({
    savedViewOrderBy: savedView.query.orderBy,
  });

  const {
    initialTableState,
    queryDescription,
    updateQueryDescription,
    currentView,
  } = useQueryDescription({
    savedViews: [savedView],
    defaultSort,
  });

  const noun = lowerCase(tableName);
  const loadingDetails = {
    loading: loadingQuerySavedView,
    loadingText: `Loading ${noun}`,
    noDataText: `No ${noun} found`,
  };

  const generatedColumnDefinitions = buildColumnDefinitions({
    columnConfig: columnConfigResponse?.getTableConfig.data ?? [],
    ignoreDefaultSize: true,
    cellRenderers: {
      ...PROPERTY_TABLE_CUSTOM_CELLS,
      ...FILES_TABLE_CUSTOM_CELLS,
      ...SUBMISSION_TABLE_CUSTOM_CELLS({ tableName }),
    },
  });

  const actionsColumn: MinimalColumnDef<
    [{ table: TABLE_NAMES; name: string }]
  > = {
    ...ACTION_COLUMN_DEF_CONSTANTS,
    cell: ({ row }) => {
      return (
        <ActionButton
          id={row.original[`${tableName}.id`]}
          tableName={tableName}
        />
      );
    },
  };

  const manualPaginationConfig = useManualPaginationConfig({
    ...initialTableState.pagination,
    currentTotalPages: currentData?.queryTableData.pageInfo.totalPages,
    previousTotalPages: previousData?.queryTableData.pageInfo.totalPages,
  });

  const tanstackColumnDefinitions: Array<
    ColumnDef<
      | QueryPropertyTableResult
      | QuerySubmissionTableResult
      | QueryFileTableResult
    >
  > = [...generatedColumnDefinitions, actionsColumn];

  const initialColumns = initializeColumns({
    initialTableState,
    tanstackColumnDefinitions,
    initialQueryDescription: queryDescription,
    actionsColumn,
  });

  if (loadingColumnConfig || !columnConfigResponse) {
    return null;
  }

  const tableConfig = columnConfigResponse.getTableConfig.data;

  const data =
    networkStatus === NetworkStatus.setVariables ? previousData : currentData;

  const timeoutError = error?.graphQLErrors.find(
    e => e.extensions.code === "TIMEOUT_ERROR"
  );

  const search = ({ page }: { page: number }) => {
    const variables: GetQueryTableDataQueryVariables = {
      description: {
        table: tableName,
        fields: mergeFieldGroups(
          queryDescription.fields,
          REQUIRED_FIELDS(tableName)
        ),
        orderBy: queryDescription.orderBy,
        filters: queryDescription.filters,
      },
      page,
    };

    void querySavedView({
      variables,
    });
  };

  return (
    <Layout hasGutters>
      <PageSubheader title={savedView.name} showDivider={false}>
        {savedView.description ? (
          <MarkdownContainer markdown={savedView.description} />
        ) : null}
      </PageSubheader>
      <FullWidthTable
        columns={initialColumns}
        previousData={previousData?.queryTableData.data}
        currentData={data?.queryTableData.data ?? []}
        loadingDetails={loadingDetails}
        tableStyleDetails={{ hasHighlights: true, hasRowActions: true }}
        manualPaginationConfig={{
          ...manualPaginationConfig,
          pageCount: data?.queryTableData.pageInfo.totalPages ?? 1,
        }}
        initialState={initialTableState}
        filterable={{
          newFilterConfiguration: tableConfig as Array<Attribute>,
          search,
        }}
        timeoutError={timeoutError}
        queryDescription={queryDescription}
        currentView={currentView}
        updateQueryDescription={updateQueryDescription}
        refetchSavedViews={() => {}}
        clientSideColumnConfig={tableConfig}
        dataSourceComputationsAffectingQuery={
          data?.queryTableData.dataSourceComputationsAffectingQuery
        }
        interactiveHeaders={false}
      />
    </Layout>
  );
};

const ActionButton = ({
  id,
  tableName,
}: {
  id: string;
  tableName: TABLE_NAMES;
}) => {
  let href: string;

  switch (tableName) {
    case TABLE_NAMES.PROPERTIES:
      href = buildLink("residentProperty", { propertyId: id });
      break;
    case TABLE_NAMES.FILES:
      href = buildLink("guestDocumentUploadFile", { id });
      break;
    default:
      href = buildLink("viewSubmission", { submissionId: id });
      break;
  }

  const actions: Array<ActionsProps> = [
    {
      label: "View",
      href,
      target: "_blank",
    },
  ];
  return <ActionCell actions={actions} />;
};

export default () => {
  const { savedViewId } = useParams<{ savedViewId: string }>();

  const { data, loading: loadingSavedView } = useGetSavedViewQuery({
    variables: { savedViewId },
  });

  if (loadingSavedView) {
    return <Loading />;
  }

  if (!data?.account?.savedView) {
    return <AccessDeniedPage resource={ACCESS_DENIED_RESOURCE.SAVED_VIEW} />;
  }

  return (
    <SavedView
      savedView={{
        ...data.account.savedView,
        isDefault: true,
        hiddenFromPublic: false,
      }}
    />
  );
};
