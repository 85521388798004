import React from "react";
import { PropertyOverviewContextProvider } from "../../AddressPanel/PropertyOverview/PropertyOverviewContext";
import { PropertyOverviewSections } from "../../AddressPanel/PropertyOverview/PropertyOverviewSections";
import Warnings from "../../AddressPanel/Warnings";
import Divider from "../../Common/Divider";
import { GuestPropertyTabProps } from ".";
import { WarningsContainer } from "./__styles__/Overview";

export const Overview = ({
  propertyId,
  latitude,
  longitude,
  propertyLabel,
}: GuestPropertyTabProps) => {
  return (
    <>
      <WarningsContainer>
        <Warnings
          propertyId={propertyId}
          loading={false}
          publicOnly={true}
          canEditWarnings={false}
          address={propertyLabel}
        />
      </WarningsContainer>
      <Divider />
      <PropertyOverviewContextProvider
        latitude={latitude}
        longitude={longitude}
      >
        <PropertyOverviewSections shouldShowRepLoss={false} />
      </PropertyOverviewContextProvider>
    </>
  );
};
