import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button";

export const PrintButton = ({ print }: { print: typeof window.print }) => {
  const { t } = useTranslation();
  return (
    <Button
      styleVariant="ghostGrey"
      leftIconName="printer"
      size="small"
      onClick={() => print()}
      style={{ padding: 0 }}
    >
      {t("button-print")}
    </Button>
  );
};
